<template>
  <div class="b-absensi-form pb-5">
    <b-form @submit.prevent="doSubmit">
      <b-row no-gutters class="m-0">
        <b-col sm="12" class="p-3">
          <b-form-group label="Kantor Cabang" class="mb-3 f-13 bold" disabled>
            <b-input class="f-13" v-model="form.data.branch_name" />
          </b-form-group>
          <b-form-group label="NIK" class="mb-3 f-13" disabled>
            <b-input class="f-13" v-model="form.data.nik" />
          </b-form-group>
          <b-row>
            <b-col>
              <b-form-group label="Sisa cuti" class="mb-3 f-13 bold" disabled>
                <b-input class="f-13" v-model="form.data.sisa_cuti" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Sisa Cuti lalu"
                class="mb-3 f-13 bold"
                disabled
              >
                <b-input class="f-13" v-model="form.data.sisa_cuti_lalu" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Sisa Izin" class="mb-3 f-13 bold" disabled>
                <b-input class="f-13" v-model="form.data.sisa_ijin" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Jenis Absen" class="mb-3 f-13 bold">
            <b-form-select
              placeholder="Pilih Jenis Absen"
              :required="!form.data.tipe"
              :options="opt.tipe"
              class="w-100 f-13"
              v-model="form.data.tipe"
              @change="updateDateRange"
            />
          </b-form-group>
          <b-form-group
            label="Peruntukan"
            class="mb-3 f-13 bold"
            v-show="form.data.tipe == 'cuti_khusus'"
          >
            <b-form-select
              placeholder="Pilih Peruntukan"
              :options="opt.khusus"
              class="w-100 f-13"
              v-model="form.data.khusus"
              @change="updateDateRange"
            />
          </b-form-group>
          <b-form-group label="Tanggal Mulai" class="mb-3 f-13 bold">
            <b-form-datepicker
              class="f-13"
              style="text-transform: capitalize"
              v-model="form.data.tgl_cuti"
              :required="!form.data.tgl_cuti"
              placeholder="Pilih tanggal"
            />
          </b-form-group>
          <b-form-group label="Tanggal Selesai" class="mb-3 f-13 bold">
            <b-form-datepicker
              class="f-13"
              style="text-transform: capitalize"
              :required="!form.data.tgl_cuti2"
              v-model="form.data.tgl_cuti2"
              placeholder="Pilih tanggal"
              :readonly="!form.data.tgl_cuti"
              :min="minDate"
              :max="maxDate"
              :disabled-dates="disableWeekendDates"
            />
          </b-form-group>
          <b-form-group label="Keterangan" class="mb-3 f-13 bold">
            <b-textarea
              class="f-13"
              rows="5"
              v-model="form.data.keterangan"
              placeholder="Keterangan tidak boleh kosong"
              :required="!form.keterangan"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" class="p-3">
          <router-link
            class="w-100 btn btn-secondary button-sag-cancel"
            to="/absensi"
            >Batal</router-link
          >
        </b-col>
        <b-col cols="6" class="p-3">
          <b-button
            block
            class="w-100 button-sag"
            type="submit"
            :disabled="form.loading"
            >{{ form.loading ? "Memproses..." : "Ajukan" }}</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<style>
.f-12 {
  font-size: 12px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-13 {
  font-size: 13px;
}
.button-sag {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #539165;
  border: none;
}
.button-sag:hover {
  background-color: #539165;
}
.button-sag-cancel {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #ce5959;
  border: none;
}
.button-sag-cancel:hover {
  background-color: #ce5959;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      form: {
        data: {
          jabatan: null,
          branch_name: null,
          branch_code: null,
          nik: null,
          sisa_cuti: 10,
          sisa_cuti_lalu: 10,
          sisa_ijin: 10,
          tipe: null,
          khusus: null,
          tgl_cuti: null,
          tgl_cuti2: null,
          keterangan: null,
        },
        loading: false,
      },
      opt: {
        tipe: [
          {
            value: null,
            text: "Silahkan Pilih Jenis Absen",
            disabled: true,
          },
          {
            value: "ijin",
            text: "Ijin",
          },
          {
            value: "cuti",
            text: "Cuti",
          },
          {
            value: "sakit",
            text: "Sakit",
          },
          {
            value: "cuti_khusus",
            text: "Cuti Khusus",
          },
          {
            value: "klk",
            text: "Kerja Luar Kantor",
          },
          {
            value: "dlk",
            text: "Dinas Luar Kantor",
          },
          {
            value: "clt",
            text: "Cuti Luar Tanggungan",
          },
        ],
        khusus: [
          {
            value: null,
            text: "Pilih Kategori Cuti Khusus",
            disabled: true,
          },
          {
            value: "2",
            text: "Istri Melahirkan/Keguguran",
          },
          {
            value: "3",
            text: "Suami/Istri/Orang Tua/Mertua/Anak/Menantu Meninggal",
          },
          {
            value: "4",
            text: "Saudara Kandung/Saudara Serumah Meninggal",
          },
          {
            value: "5",
            text: "Pernikahan Anak/Menjadi Wali Dengan Keterangan Yang Sah",
          },
          {
            value: "6",
            text: "Khitanan Anak",
          },
          {
            value: "7",
            text: "Umroh",
          },
          {
            value: "8",
            text: "Bersalin",
          },
          {
            value: "9",
            text: "Menikah",
          },
          {
            value: "10",
            text: "Musibah Tertentu (Kebakaran/Banjir/Gempa)",
          },
          {
            value: "11",
            text: "Ujian/Wisuda(Karyawan/Pasangan/Anak)",
          },
          {
            value: "12",
            text: "Haid",
          },
        ],
      },
      minDate: null,
      maxDate: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["logout"]),
    setUpForm() {
      let userData = this.user.data;
      this.form.data.nik = userData.nik;
      this.form.data.jabatan = userData.jabatan;
      this.form.data.branch_code = userData.branch_code;
      this.form.data.branch_name = userData.branch_name;
    },
    async getHakAbsensi() {
      this.form.loading = true;
      let url = `presence/hak_cuti_ijin?nik=${this.user.data.nik}`;
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let req = await axios.get(url, header);
      if (req.data.status) {
        this.form.loading = false;
        this.form.data.sisa_cuti = req.data.data.hak_cuti;
        this.form.data.sisa_cuti_lalu = req.data.data.hak_cuti_lalu;
        this.form.data.sisa_ijin = req.data.data.hak_ijin;
      } else {
        this.form.loading = false;
      }
    },
    async doSubmit() {
      this.form.loading = true;
      let url = "/presence/cuti";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      // Validating tgl_cuti and tgl_cuti2
      if (!this.form.data.tgl_cuti) {
        this.form.loading = false;
        this.notif("Tanggal mulai cuti harus diisi", "Gagal", "danger");
        return;
      }
      if (!this.form.data.tgl_cuti2) {
        this.form.loading = false;
        this.notif("Tanggal selesai cuti harus diisi", "Gagal", "danger");
        return;
      }
      // Validating form.data.khusus for type "cuti khusus"
      if (this.form.data.tipe === "cuti_khusus" && !this.form.data.khusus) {
        this.form.loading = false;
        this.notif(
          "Peruntukan harus diisi untuk cuti khusus",
          "Gagal",
          "danger"
        );
        return;
      }
      // Calculate the number of weekdays of leave requested
      let tglCuti = new Date(this.form.data.tgl_cuti);
      let tglCuti2 = new Date(this.form.data.tgl_cuti2);
      let diffDays = this.calculateWeekdays(tglCuti, tglCuti2);

      // Check if the leave days exceed the available leave
      if (
        this.form.data.tipe === "cuti" &&
        diffDays > this.form.data.sisa_cuti + this.form.data.sisa_cuti_lalu
      ) {
        this.form.loading = false;
        this.notif(
          "Jumlah hari cuti melebihi hak cuti yang tersedia",
          "Gagal",
          "danger"
        );
        return;
      }
      if (
        this.form.data.tipe === "ijin" &&
        diffDays > this.form.data.sisa_ijin
      ) {
        this.form.loading = false;
        this.notif(
          "Jumlah hari ijin melebihi hak ijin yang tersedia",
          "Gagal",
          "danger"
        );
        return;
      }

      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        this.form.loading = false;
        this.notif("Pengajuan Absen Berhasil", "Sukses", "success");
        setTimeout(() => {
          this.$router.push("/absensi");
        }, 2000);
      } else {
        this.form.loading = false;
        this.logout();
        this.notif(
          "Pengajuan Absen Gagal (Kesalahan pada server)",
          "Gagal",
          "danger"
        );
      }
    },
    calculateWeekdays(startDate, endDate) {
      let count = 0;
      let curDate = new Date(startDate);
      while (curDate <= endDate) {
        let dayOfWeek = curDate.getDay();
        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
          // 6 = Saturday, 0 = Sunday
          count++;
        }
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    },
    clearForm() {
      this.form.data = {
        branch_code: null,
        nik: null,
        sisa_cuti: 0,
        sisa_cuti_lalu: 0,
        sisa_ijin: 0,
        tipe: null,
        khusus: null,
        tgl_cuti: null,
        tgl_cuti2: null,
        keterangan: null,
      };
      this.setUpForm();
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-full",
      });
    },
    updateDateRange() {
      if (this.form.data.tipe === "cuti" && this.form.data.tgl_cuti) {
        const fromDate = new Date(this.form.data.tgl_cuti);
        let count = 0;
        let toDate = new Date(fromDate);

        while (count < 4) {
          toDate.setDate(toDate.getDate() + 1);

          if (toDate.getDay() !== 0 && toDate.getDay() !== 6) {
            count++;
          }
        }

        this.minDate = fromDate.toISOString().split("T")[0];
        this.maxDate = toDate.toISOString().split("T")[0];
      } else if (
        (this.form.data.khusus === "4" ||
          this.form.data.khusus === "11" ||
          this.form.data.khusus === "12") &&
        this.form.data.tgl_cuti
      ) {
        const fromDate = new Date(this.form.data.tgl_cuti);
        let count = 0;
        let toDate = new Date(fromDate);

        while (count < 1) {
          toDate.setDate(toDate.getDate());

          if (toDate.getDay() !== 0 && toDate.getDay() !== 6) {
            count++;
          }
        }

        this.minDate = fromDate.toISOString().split("T")[0];
        this.maxDate = toDate.toISOString().split("T")[0];
      } else if (
        (this.form.data.khusus === "2" ||
          this.form.data.khusus === "3" ||
          this.form.data.khusus === "5" ||
          this.form.data.khusus === "6" ||
          this.form.data.khusus === "10") &&
        this.form.data.tgl_cuti
      ) {
        const fromDate = new Date(this.form.data.tgl_cuti);
        let count = 0;
        let toDate = new Date(fromDate);

        while (count < 1) {
          toDate.setDate(toDate.getDate() + 1);

          if (toDate.getDay() !== 0 && toDate.getDay() !== 6) {
            count++;
          }
        }

        this.minDate = fromDate.toISOString().split("T")[0];
        this.maxDate = toDate.toISOString().split("T")[0];
      } else if (this.form.data.khusus === "9" && this.form.data.tgl_cuti) {
        const fromDate = new Date(this.form.data.tgl_cuti);
        let count = 0;
        let toDate = new Date(fromDate);

        while (count < 1) {
          toDate.setDate(toDate.getDate() + 2);

          if (toDate.getDay() !== 0 && toDate.getDay() !== 6) {
            count++;
          }
        }

        this.minDate = fromDate.toISOString().split("T")[0];
        this.maxDate = toDate.toISOString().split("T")[0];
      } else if (this.form.data.khusus === "7" && this.form.data.tgl_cuti) {
        const fromDate = new Date(this.form.data.tgl_cuti);
        let count = 0;
        let toDate = new Date(fromDate);

        while (count < 11) {
          toDate.setDate(toDate.getDate() + 1);

          if (toDate.getDay() !== 0 && toDate.getDay() !== 6) {
            count++;
          }
        }

        this.minDate = fromDate.toISOString().split("T")[0];
        this.maxDate = toDate.toISOString().split("T")[0];
      } else if (this.form.data.khusus === "8" && this.form.data.tgl_cuti) {
        const fromDate = new Date(this.form.data.tgl_cuti);
        let count = 0;
        let toDate = new Date(fromDate);

        while (count < 66) {
          toDate.setDate(toDate.getDate() + 1);

          if (toDate.getDay() !== 0 && toDate.getDay() !== 6) {
            count++;
          }
        }

        this.minDate = fromDate.toISOString().split("T")[0];
        this.maxDate = toDate.toISOString().split("T")[0];
      } else {
        this.minDate = null;
        this.maxDate = null;
        this.form.data.tgl_cuti2 = "";
      }
    },
    disableWeekendDates(date) {
      const dayOfWeek = date.getDay();
      return dayOfWeek === 0 || dayOfWeek === 6;
    },
  },
  watch: {
    "form.data.tgl_cuti": function (newDate) {
      if (this.form.data.tipe === "cuti" && newDate) {
        this.updateDateRange();
        this.form.data.tgl_cuti2 = "";
      } else if (
        (this.form.data.khusus === "2" ||
          this.form.data.khusus === "3" ||
          this.form.data.khusus === "4" ||
          this.form.data.khusus === "5" ||
          this.form.data.khusus === "6" ||
          this.form.data.khusus === "6" ||
          this.form.data.khusus === "7" ||
          this.form.data.khusus === "8" ||
          this.form.data.khusus === "9" ||
          this.form.data.khusus === "10" ||
          this.form.data.khusus === "11" ||
          this.form.data.khusus === "12") &&
        newDate
      ) {
        this.updateDateRange();
        this.form.data.tgl_cuti2 = "";
      }
    },
    "form.data.tipe": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.form.data.tgl_cuti = null;
        this.form.data.tgl_cuti2 = null;
      }
    },
  },
  mounted() {
    this.setUpForm();
    this.getHakAbsensi();
  },
};
</script>
